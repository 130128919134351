<template>
  <div style="text-align: left">
    <div class="row w-100 mt-5 mb-4">
      <div class="col-12 pl-3 px-0 mb-0 text-left">
        <BreadCrumbComponent :pageTitles="['Destinations', 'Edit Destination']" />
      </div>
    </div>

    <article>
      <section>
        <div
          v-loading="isUploadingFile || isLoading"
          class="mt-5"
        >
          <el-form
            :model="destinationForm"
            :rules="destinationRules"
            ref="destinationForm"
            class="demo-destinationForm2"
          >
            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Destination Country"
                  prop="country_title"
                >
                  <el-input v-model="destinationForm.country_title"></el-input>
                </el-form-item>
              </div>

              <div class="col-md-6">
                <el-form-item
                  label="Rates"
                  prop="rates_usd"
                >
                  <el-input
                    v-model="destinationForm.rates_usd"
                    placeholder="Rates"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Best time to go"
                  prop="best_time_to_go"
                >
                  <el-input
                    v-model="destinationForm.best_time_to_go"
                    placeholder="Best time to go"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="High Season"
                  prop="high_season"
                >
                  <el-input
                    v-model="destinationForm.high_season"
                    placeholder="High Season"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Size"
                  prop="size"
                >
                  <el-input
                    v-model="destinationForm.size"
                    placeholder="Size"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-6"></div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Country Overview"
                  prop="overview"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.overview"
                    :rows="2"
                    placeholder="Type overview here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="Pros and Cons"
                  prop="pros_and_cons"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.pros_and_cons"
                    :rows="2"
                    placeholder="Type here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Wild Life"
                  prop="wild_life"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.wild_life"
                    :rows="2"
                    placeholder="Type here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="Weather and Climate"
                  prop="weather_and_climate"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.weather_and_climate"
                    :rows="2"
                    placeholder="Type here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item
                  label="Best time to visit"
                  prop="best_time_to_visit"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.best_time_to_visit"
                    :rows="2"
                    placeholder="Type here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="Premier parks and reserves"
                  prop="premier_parks_and_reserves"
                >
                  <el-input
                    type="textarea"
                    v-model="destinationForm.premier_parks_and_reserves"
                    :rows="2"
                    placeholder="Type here..."
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <el-form-item label="Country Location on Africa map">
                  <el-upload
                    class="upload-demo"
                    drag
                    action="''"
                    :on-change="handleAfricanMapPreview"
                    :on-remove="handleAfricanMapRemove"
                    :file-list="africaMapFileLists"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isUploadingFile">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div v-else>
                      <el-progress
                        v-if="imageUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="imageUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                    <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                  </el-upload>
                </el-form-item>
                <img
                  :src="selectedAfricaMapFile"
                  style="width: 50px; height: 50px; object-fit: cover;"
                >
              </div>
              <div class="col-md-6">
                <el-form-item label="Country map photo">
                  <el-upload
                    class="upload-demo"
                    drag
                    action="''"
                    :on-change="handleCountryMapPreview"
                    :on-remove="handleCountryMapRemove"
                    :file-list="countryMapFileLists"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isUploadingFile">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div v-else>
                      <el-progress
                        v-if="imageUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="imageUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                    <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                  </el-upload>
                </el-form-item>
                <img
                  :src="selectedCountryMapFile"
                  style="width: 50px; height: 50px; object-fit: cover;"
                >
              </div>
              <div class="col-md-6">
                <el-form-item label="Country Featured Photo">
                  <el-upload
                    class="upload-demo"
                    drag
                    action="''"
                    :on-change="handleFeaturedPhotoPreview"
                    :on-remove="handleFeaturedPhotoRemove"
                    :file-list="featuredPhotoFileLists"
                    :auto-upload="false"
                    :multiple="false"
                  >
                    <div v-if="!isUploadingFile">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </div>
                    <div v-else>
                      <el-progress
                        v-if="imageUploadingProgress != 100"
                        type="circle"
                        :color="progressColors"
                        :percentage="imageUploadingProgress"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <el-progress
                        v-else
                        type="circle"
                        :percentage="100"
                        status="success"
                        :width="80"
                        :stroke-width="4"
                      ></el-progress>
                      <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                        {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                      </div>
                    </div>
                    <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                  </el-upload>
                </el-form-item>
                <img
                  :src="selectedFeaturedPhotoFile"
                  style="width: 50px; height: 50px; object-fit: cover;"
                >
              </div>
              <div class="col-md-6">
                <el-form-item
                  label="Feautured Video URL"
                  prop="featured_video_url"
                >
                  <el-input
                    v-model="destinationForm.featured_video_url"
                    placeholder="Video URL"
                  >
                  </el-input>
                </el-form-item>
                <iframe
                  width="100%"
                  height="215"
                  :src="destinationForm.featured_video_url"
                >
                </iframe>
              </div>
            </div>

            <hr />
            <el-form-item class="text-center">
              <el-button
                type="primary"
                @click="editDestination('destinationForm')"
              >Save changes</el-button>
            </el-form-item>
          </el-form>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
export default {
  components: {
    BreadCrumbComponent,
  },
  data() {
    return {
      addPackageModal: false,
      selectedAfricaMapFile: null,
      selectedFeaturedPhotoFile: null,
      selectedCountryMapFile: null,
      countryId: `${this.$route.params.countryId}`,
      destination_country: "",

      isUploadingFile: false,
      value: "",
      loading: false,
      isLoading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      destinationForm: {
        country_title: "",
        overview: "",
        featured_video_url: "",
        rates_usd: "",
        best_time_to_go: "",
        high_season: "",
        size: "",
        pros_and_cons: "",
        wild_life: "",
        weather_and_climate: "",
        best_time_to_visit: "",
        premier_parks_and_reserves: "",
      },
      africaMapFileLists: [],
      featuredPhotoFileLists: [],
      countryMapFileLists: [],

      destinationRules: {
        country_title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
        overview: [
          {
            required: true,
            message: "Overview is required",
            trigger: "blur",
          },
        ],
        featured_video_url: [
          {
            required: true,
            message: "Featured video url is required",
            trigger: "blur",
          },
        ],
        rates_usd: [
          {
            required: true,
            message: "Rates are required",
            trigger: "blur",
          },
        ],
        best_time_to_go: [
          {
            required: true,
            message: "Best time to go is required",
            trigger: "blur",
          },
        ],
        high_season: [
          {
            required: true,
            message: "High Season is required",
            trigger: "blur",
          },
        ],
        size: [
          {
            required: true,
            message: "Size is required",
            trigger: "blur",
          },
        ],
        pros_and_cons: [
          {
            required: true,
            message: "Pros and Cons are required",
            trigger: "blur",
          },
        ],
        wild_life: [
          {
            required: true,
            message: "Wildlife is required",
            trigger: "blur",
          },
        ],
        weather_and_climate: [
          {
            required: true,
            message: "Weather and climate is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.getOneCountry();
  },

  methods: {
    handleClose(done) {
      done();
    },

    handleAfricanMapPreview(file) {
      console.log(file.raw);
      this.africaMapFileLists = [file];
      this.selectedAfricaMapFile = file.raw;
    },
    handleAfricanMapRemove() {
      this.selectedAfricaMapFile = null;
    },

    handleFeaturedPhotoPreview(file) {
      console.log(file.raw);
      this.featuredPhotoFileLists = [file];
      this.selectedFeaturedPhotoFile = file.raw;
    },
    handleFeaturedPhotoRemove() {
      this.selectedFeaturedPhotoFile = null;
    },

    handleCountryMapPreview(file) {
      console.log(file.raw);
      this.countryMapFileLists = [file];
      this.selectedCountryMapFile = file.raw;
    },
    handleCountryMapRemove() {
      this.selectedCountryMapFile = null;
    },

    async getOneCountry() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/destination/country/get/${this.$route.params.countryId}`
        );
        if (request.data.success) {
          this.destination_country = request.data.destination;
          this.selectedAfricaMapFile =
            request.data.destination.africa_country_location;
          this.selectedFeaturedPhotoFile =
            request.data.destination.featured_photo;
          this.selectedCountryMapFile = request.data.destination.country_map;
          this.destinationForm.country_title =
            request.data.destination.country_title;
          this.destinationForm.overview = request.data.destination.overview;
          this.destinationForm.featured_video_url =
            request.data.destination.featured_video_url;
          this.destinationForm.rates_usd = request.data.destination.rates_usd;
          this.destinationForm.best_time_to_go =
            request.data.destination.best_time_to_go;
          this.destinationForm.high_season =
            request.data.destination.high_season;
          this.destinationForm.size = request.data.destination.size;
          this.destinationForm.pros_and_cons =
            request.data.destination.pros_and_cons;
          this.destinationForm.wild_life = request.data.destination.wild_life;
          this.destinationForm.weather_and_climate =
            request.data.destination.weather_and_climate;
          this.destinationForm.best_time_to_visit =
            request.data.destination.best_time_to_visit;
          this.destinationForm.premier_parks_and_reserves =
            request.data.destination.premier_parks_and_reserves;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Package Details now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async editDestination(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedAfricaMapFile == null) {
            this.$notify({
              title: "Empty",
              message: "Profile picture is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append(
              "country_title",
              this.destinationForm.country_title
            );
            formData.append("featured_photo", this.selectedFeaturedPhotoFile);
            formData.append("country_map", this.selectedCountryMapFile);
            formData.append(
              "africa_country_location",
              this.selectedAfricaMapFile
            );
            formData.append("overview", this.destinationForm.overview);
            formData.append(
              "featured_video_url",
              this.destinationForm.featured_video_url
            );
            formData.append("rates_usd", this.destinationForm.rates_usd);
            formData.append(
              "best_time_to_go",
              this.destinationForm.best_time_to_go
            );
            formData.append("high_season", this.destinationForm.high_season);
            formData.append("size", this.destinationForm.size);
            formData.append(
              "pros_and_cons",
              this.destinationForm.pros_and_cons
            );
            formData.append("wild_life", this.destinationForm.wild_life);
            formData.append(
              "best_time_to_visit",
              this.destinationForm.best_time_to_visit
            );
            formData.append(
              "weather_and_climate",
              this.destinationForm.weather_and_climate
            );
            formData.append(
              "premier_parks_and_reserves",
              this.destinationForm.premier_parks_and_reserves
            );

            let request = await this.$http.post(
              `api/safari/destination/country/edit/${this.$route.params.countryId}`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "DESTINATION_COUNTRY_EDITED_SUCCESSFULLY"
            ) {
              this.addPackageModal = false;
              this.$router.push({ path: `/countries` });
              this.$notify({
                title: "Success",
                message: "Country of Destination Updated Successfully",
                type: "success",
              });
            } else if (request.data.message == "safari_tour  exists") {
              this.$notify({
                title: "Duplicate",
                message: "Package Exists",
                type: "warning",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to save changes now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
